<template>
  <div class="modal-card">
    <div class="modal-content">
      <div class="box">
        <div class="columns is-mobile">
          <div class="column">
            <div class="tabs is-toggle is-small" v-if="hasTabs">
              <ul>
                <li
                  @click="switchTab(tab)"
                  v-for="tab in tabs"
                  :key="tab"
                  :class="{ 'is-active': selectedTab === tab }"
                >
                  <a>
                    <span class="is-size-6 is-capitalized">
                      {{ tabTitle(tab) }}
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <p class="subtitle has-text-primary is-capitalized" v-else>
              {{ title }}
            </p>
          </div>
          <div class="column is-narrow">
            <a class="has-text-danger" @click="$modal.close">Close</a>
          </div>
        </div>
        <div :class="{ 'is-mobile': $mq === 'mobile' }">
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <td
                  v-for="(column, index) in columns"
                  :key="index"
                  class="is-size-7 has-text-weight-semibold"
                >
                  {{ column }}
                </td>
              </tr>
            </thead>
            <tbody class="is-inverse-stripey">
              <tr v-for="(row, index) in tabRows" :key="index">
                <td>
                  <p>{{ row.sellerType || '-' }}</p>
                </td>
                <td>
                  <p>
                    {{ value(row, 'daysOnSaleMin') }}
                  </p>
                </td>
                <td>
                  <p>
                    {{ value(row, 'daysOnSaleMax') }}
                  </p>
                </td>
                <td>
                  <p>
                    {{ value(row, 'pricePercentageMin') }}
                  </p>
                </td>
                <td>
                  <p>
                    {{ value(row, 'pricePercentageMax') }}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'PricingStrategy',
  data: () => ({ selectedTab: null }),
  mounted() {
    const { hasTabs, tabs, rows } = this
    this.selectedTab = hasTabs ? tabs[0] : rows[0].vehicleStatus
  },
  computed: {
    ...mapState({
      rows: state => state.modal.config
    }),
    tabs() {
      return [...new Set(this.rows?.map(row => row.vehicleStatus))]
    },
    title() {
      return `${this.selectedTab} Pricing Strategy`
    },
    hasTabs() {
      return this.tabs.length > 1
    },
    tabRows() {
      const { rows, tabs, selectedTab } = this
      if (tabs.length === 1) return rows
      return rows.filter(row => row.vehicleStatus === selectedTab)
    },
    columns() {
      return [
        'Valuation type',
        'Min days on sale',
        'Max days on sale',
        'Min price percentage',
        'Max price percentage'
      ]
    }
  },
  methods: {
    switchTab(tab) {
      this.selectedTab = tab
    },
    tabTitle(tab) {
      return `${tab} Pricing Strategy`
    },
    value(row, column) {
      return typeof row[column] === 'number' ? row[column] : '-'
    }
  }
}
</script>

<style lang="sass" scoped>
.is-mobile
  white-space: nowrap
  overflow-x: auto
</style>
